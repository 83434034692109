<script setup>
import { onMounted } from 'vue';
import { ref } from 'vue';
import TheContact from '@/components/TheContact.vue';

onMounted(() => {
    document.querySelector('.header-img').scrollIntoView();
    document.querySelector('.logo').scrollIntoView();    
})

const view = ref('intro');
/* const showMenu = ref(false );

function setView(newView) {
  view.value = newView;
  showMenu.value = false;
} */

</script>

<template>
<TheContact v-if="view === 'contact'" />
    <div class="title">
        <div class="title-container">
            <h1>Beurer</h1>
            <h2>Besigheim</h2>
        </div>
    </div>
    <img src="@/assets/title-img.jpg" alt="" class="header-img" />

    <p class="section-title">WILLKOMMEN</p>
    <div class="line"></div>

    <div class="text-box">
        <p class="text">Betreten Sie die Schreibwarenabteilung, begrüßt Sie Jürgen Beurer oder eine unserer Mitarbeiterinnen. Hier im Erdgeschoß finden Sie alles, was man für die Schule oder das Büro, zum Verpacken oder Verschenken, Basteln oder Zeichnen braucht. 
            Die sympathische Atmospäre lädt zum Stöbern ein, gerne beraten wir Sie auch individuell. Bekannt sind wir unter anderem für unsere große Auswahl an Glückwunschkarten zu jeden Anlass.
        </p>
    </div>
    <img src="@/assets/homepage-collage.png" alt="" class="collage">


    <div class="banner-wrapper">
    <div class="schulstart-div">
        <section class="section">
            <img class="banner" src="@/assets/schulstart-banner.jpg">
            <img class="banner-mobile" src="@/assets/banner-mobile.png">
            <div class="text-banner"> 
                <p class="center">Schneller als man denkt sind die Ferien vorbei! <br> Das neue Schuljahr beginnt!</p> 
                <p class="information">Wie jedes Jahr haben wir in der Schulanfangswoche durchgehend von 7:00 Uhr - 
                    20:00Uhr geöffnet. <br> Gerne können Sie uns ihre Artikelliste per Mail zu kommen lassen und die 
                    gepackte Tüte abholen. <br> Alle Erstklässler bekommen zu Ihrer Bestellung eine GRATIS Kiste, 
                    gefüllt mit diversen Mustern. 
                </p>
                <button class="center-button" @click="$emit('navigate', 'contact')">Jetzt Mail Schicken!</button> 
            </div>
        </section>
    </div>
</div>

<p class="section-title">NEWS</p>
<div class="line"></div>

    <div class="news-flex">
        <!-- <div data-aos-easing="ease-out-cubic" data-aos-duration="1000" class="event1"> -->
        <div class="event1">
            <div class="top">
                <p class="date">04.03.2024(Nachmittag)    |</p>
                <p class="subtitle">GESCHLOSSEN</p>
            </div>
            <div class="img-text">
                <img class="image2" src="@/assets/news-image3.jpg" alt="">
                <p class="info">
                    Unser Geschäft bleibt am Montag, den 04.03.2024 Nachmittags ausnahmsweise geschlossen.
                </p>
            </div>
        </div>
        
        <div class="event2">
            <div class="top">
                <p class="date">01.01.2024    |</p>
                <p class="subtitle">NEUES JAHR</p>
            </div>
            <div class="img-text">
                <img class="image1" src="@/assets/schild_23_24.png" alt="">
                <p class="info">
                    Das neue Jahr hat begonnen! Wir freuen uns sie wieder in unserem Laden begrüßen zu dürfen.
                </p>
            </div>
        </div>
    </div>

</template>

<style scoped>


/* Schulstart flex */
.banner-wrapper {
    widows: 100%;
    height: auto;
    /* border: 1px solid #111; */
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.schulstart-div {
    width: 80%;
    /* margin-left: 10%; */
    margin-top: 4.5%;
    margin-bottom: 150px;
}

.section {
    padding: 20px;
    background-color: #f0f0f0;
    /* margin: 10px; */
    border-radius: 10px;
    width: 100%;
}

.banner {
  width: 100%;
  border-radius: 15px;
}

.banner-mobile {
    display: none;
}

.text-banner {
    font-size: 20px;
    color: #666;
    margin-top: 5%;
    padding-left: 5vw;
    padding-right: 5vw;
    margin-bottom: 4vh;
}
.center{
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 4vh;
}
.center-button{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    border-radius: 10px;
    background-color:rgb(255, 213, 0); 
    border: none;
    margin-top: 20px;
    padding: 10px;
}
.center-button:hover{
    background-color:rgb(183, 153, 0); 
}


@media screen and (max-width: 768px) {

    .section {
        padding: 10px;
    }

    .banner {
        font-size: 20px;
    }

    .text {
        font-size: 14px;
    }
}

@media screen and (max-width: 600px) {
    .banner {
        display: none;
    }

    .banner-mobile {
        padding: 0;
        margin: 0;
        width: 72vw;
        height: auto;
        display: inline-block;
        border-radius: 10px;
    }
}

@media screen and (max-width: 500px) {
    .banner-mobile {
        width: 74vw;
    }
}

.header-img {
  width: 100%;
  height: 93vh;
  object-fit: cover;
  object-position: bottom left;
}

.title {
    width: 100%;
    height: 93vh;
    /* border: 1px solid; */
    position: absolute;
    z-index: 1;
    /* padding-top: 250px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.title h1 {
  text-shadow: 4px 4px #a17100;
  font-size: 120px;
  font-weight: 900;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: rgb(255, 255, 255);
  /* -webkit-text-stroke: 1px rgb(0, 0, 0); */
}

.title h2 {
  text-shadow: 4px 4px #a17100;
  font-size: 120px;
  font-weight: 900;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: rgb(255, 255, 255);
  /* -webkit-text-stroke: 2px rgb(0, 0, 0); */
  margin-top: -50px;
}

.section-title{
    padding-top: 50px;
    font-size: 50px;
    color: black;
}

/* @supports (-webkit-touch-callout: none) { */
  @media all and (max-width: 800px){
    .title h1 {
      font-size: 90px;
    }
    .title h2 {
      font-size: 90px;
      margin-top: -30px;
    }
}
  @media all and (max-width: 500px){
    .title h1 {
      font-size: 70px;
    }
    .title h2 {
      font-size: 70px;
    }
  }
/* } */

.line{
    width: 2px;
    height: 60px;
    background-color: black;
    margin-left: 50%;
    margin-top: 40px;
    margin-bottom: 70px;
}

.text-box{
    width: 60%;
    margin-left: 20%;
    height: 50px;
    margin-bottom: 350px;
}
.text{
    font-size: 30px;
    text-align: justify;
}
.collage{
    max-width: 60%;
    height: auto;
    width: auto\9; /* ie8 */
    width: 60%;
    margin-bottom: 80px;

}

.event1{
    background-color: rgb(12, 32, 72);
    margin-right: 70px;
    width: 50%;
    border-radius: 10px;
    margin: auto;

}
.event2{
    background-color: rgb(12, 32, 72);
    width: 50%;
    border-radius: 10px;
}
.top{
    display: flex;
    align-items: flex-start;
    margin-left: 20px;
    margin-top: 20px;
}
.subtitle{
    color:rgb(255, 213, 0);
    margin-left: 5px;
}

.events-box img {
    width: 300px;
    padding-top: 15px;
}
.img-text {
    display: flex;
    align-items: flex-start;
    margin-left: 20px;
    margin-top: 20px;
}
.image1 {
    width: 25%;
    height: 20%;
    scale: 1.1;
}
.image2 {
    width: 25%;
    height: 10%;
    margin-bottom: 30px;
    border-radius: 5px;
}
.info{
    text-align: left;
    margin-top: -5px;
    margin-left: 15px;
    margin-right: 20px;

}

.news-flex{
    display: flex;
    /* display: block; */
    /* block for mobile */
    align-items: flex-start;
    gap: 1vw;   
    width: 80%;
    margin-left: 10%;
    margin-bottom: 150px;
    
}
.date{
    color: white;
    font-size: 20px;
    padding: 15px 0 0 0;

}
.subtitle{
    font-size: 20px;
    padding: 15px 0 0 0;


}
.info{
    color: white;
    font-size: 20px;
    padding: 0 0 30px 0;
}
.image1{
    padding: 0 0 30px 0;
}



@media all and (max-width: 1000px){
    .news-flex{
    display: block; 
    }
    .event1{
        background-color: rgb(12, 32, 72);
        margin-right: 0px;
    }
    .info{
        padding-right: 20px;
    }
    .event1 {
        width: 100%;
    }
    .event2 {
        width: 100%;
    }
}




@media all and (max-width: 1500px){
    .text{
        font-size: 27px;
    }
}
@media all and (max-width: 1300px){
    .text{
        font-size: 25px;
    }
}
@media all and (max-width: 1000px){
    .text{
        font-size: 23px;
        margin-top: 30px;
    }
}
@media all and (max-width: 800px){
    .text{
        font-size: 20px;
        margin-top: 30px;

    }
    .section-title{
    font-size: 45px;
    }
}
@media all and (max-width: 700px){
    .text{
        font-size: 19px;
        margin-top: 60px;

    }
    .section-title{
    font-size: 40px;
    }
}

@supports (-webkit-touch-callout: none) {
    @media all and (max-width: 700px){
    .text-box{
    width: 60%;
    margin-left: 20%;
    height: 50px;
    margin-top: 90px;
    margin-bottom: 300px;
}
    .collage{
    margin-top: 40vh;
    max-width: 60%;
    height: auto;
    width: auto\9; /* ie8 */
    width: 60%;
    margin-bottom: 80px;
}

    }
}
@media all and (max-width: 600px){

    .date{
    color: white;
    font-size:18px;
    padding: 15px 0 0 0;
    
    }
    .line{
    height: 35px;
    margin-top: 20px;
    margin-bottom: 20px;
    }
    .schulstart-div {
    width: 80%;
    /* margin-left: 10%; */
    margin-top: 4.5%;
    margin-bottom: 50px;
}


.subtitle{
    font-size:18px;
    padding: 15px 0 0 0;
}
.info{
    color: white;
    font-size: 18px;
    padding: 0 0 30px 0;


}
}
@media all and (max-width: 500px){
    .text{
        font-size: 17px;
        margin-top: 80px;
        margin-bottom: 20px;

    }
    .section-title{
    font-size: 35px;
    }
    .date{
    color: white;
    font-size:16px;
    padding: 15px 0 0 0;

}
.subtitle{
    font-size:16px;
    padding: 15px 0 0 0;


}
.info{
    color: white;
    font-size: 16px;
    padding: 0 0 30px 0;


}

}
@supports (-webkit-touch-callout: none) {
    @media all and (max-width: 500px){
    .text{
        font-size: 17px;
        margin-top: 80px;
        margin-bottom: 20px;

    }
    .line{
    height: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
    }
    .section-title{
    font-size: 35px;
    }
    .collage{
    margin-top: 200px;
    }
    .text-box{
    width: 80%;
    margin-left: 10%;

}

.events-box img{
    width: 400px;
    height: 300px;
    padding-top: 85px;
}
.date{
    color: white;
    font-size:15px;
    padding: 15px 0 0 0;

}
.subtitle{
    font-size:15px;
    padding: 15px 0 0 0;


}
.info{
    color: white;
    font-size: 15px;
    padding: 0 0 30px 0;


}
}
}
@media all and (max-width: 460px){
    .text{
        font-size: 16px;
    }
    .section-title{
    font-size: 30px;
    }
}
@media all and (max-width: 400px){
    .text{
        font-size: 15px;


    }
    .section-title{
    font-size: 30px;
    }

}
@media all and (max-width: 352px){
    .text{
        font-size: 14px;
    }
    .section-title{
    font-size: 30px;
    }

}







/* Media for welcome part */

@media all and (max-width: 1500px){
    .text{
        font-size: 27px;
    }
}
@media all and (max-width: 1300px){
    .text{
        font-size: 25px;
    }
}
@media all and (max-width: 1000px){
    .text{
        font-size: 23px;
        margin-top: 60px;
    }
}
@media all and (max-width: 800px){
    .text{
        font-size: 20px;
        margin-top: 60px;

    }
    .section-title{
    font-size: 45px;
    }
}
@media all and (max-width: 700px){
    .text{
        font-size: 19px;
        margin-top: 60px;

    }
    .section-title{
    font-size: 40px;
    }
}

@supports (-webkit-touch-callout: none) {
    @media all and (max-width: 700px){
    .text-box{
    width: 60%;
    margin-left: 20%;
    height: 50px;
    margin-top: 90px;
    margin-bottom: 300px;
}
    .collage{
    max-width: 60%;
    height: auto;
    width: auto\9; /* ie8 */
    width: 60%;
    margin-bottom: 50px;
}

    }
}
@media all and (max-width: 600px){
    .text{
        font-size: 18px;
        margin-top: 70px;
        margin-bottom: 20px;

    }
    .section-title{
    font-size: 40px;
    }
}
@media all and (max-width: 500px){
    .text{
        font-size: 17px;
        margin-top: 50px;
        margin-bottom: 20px;

    }
    .section-title{
    font-size: 35px;
    }

}
@supports (-webkit-touch-callout: none) {
    @media all and (max-width: 500px){
    .text{
        font-size: 17px;
        margin-top: 80px;
        margin-bottom: 20px;

    }
    .section-title{
    font-size: 35px;
    }
    .collage{
    margin-top: 50px;
    }
    .text-box{
    width: 80%;
    margin-left: 10%;

}
.collage{
    margin-top: 0px;
    max-width: 80%;
    height: auto;
    width: auto\9; /* ie8 */
    width: 80%;
    margin-bottom: 80px;

}
}
}
@media all and (max-width: 460px){
    .text{
        font-size: 16px;
    }
    .section-title{
    font-size: 30px;
    }
}
@media all and (max-width: 400px){
    .text{
        font-size: 15px;


    }
    .section-title{
    font-size: 30px;
    }

}
@media all and (max-width: 352px){
    .text{
        font-size: 14px;
    }
    .section-title{
    font-size: 30px;
    }
    .collage{
    margin-top: 0px;
    }

}

</style>