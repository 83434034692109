import { createApp } from 'vue';
// import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faHouse, faEnvelope, faPhone, faMapLocationDot, faLocationDot, faHeart, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add([faHouse, faEnvelope, faPhone, faFacebookF, faMapLocationDot, faLocationDot, faHeart, faAngleUp])

const app = createApp(App)
// app.use(router)
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
