<script setup>
import { ref } from 'vue';

import TheIntro from '@/components/TheIntro.vue';

import TheStationery from '@/components/TheStationery.vue';
import TheLibrary from '@/components/TheLibrary.vue';
import TheEvents from '@/components/TheEvents.vue';
import TheContact from '@/components/TheContact.vue';
import TheImpressum from '@/components/TheImpressum.vue';


const view = ref('intro');
const showMenu = ref(false);

function setView(newView) {
  view.value = newView;
  showMenu.value = false;
}
</script>

<template>
<div class="container">
  <div class="header">
    
    <div class="square" @click="showMenu = !showMenu">
      <div class="hamburger-line1"></div>
      <div class="hamburger-line2"></div>
      <div class="hamburger-line3"></div>
      <!-- <div class="dropdown-content"></div> -->
    </div>

    <div class="button">
      <a href="https://www.buchkatalog-reloaded.de/170208" target="_blank">
        <div class="button-outline">
        <div class="overlay">
            <p>ONLINE SHOP</p>
        </div>
        <p class="shop-link">ONLINE SHOP</p>
        </div>
      </a>
    </div>
    <div class="symbols">
      <a href="https://www.facebook.com/BuchhandlungBeurer" target="_blank">
        <font-awesome-icon icon="fa-brands fa-facebook-f" class="facebook-icon" />
      </a>
    </div>

    <div class="menu" :class="showMenu ? 'open-menu' : 'closed-menu'">
      <ul>
        <li><button :class="{ active: view == 'intro' }" @click="setView('intro')">HOME</button></li>
        <li><button :class="{ active: view == 'events' }" @click="setView('events')">EVENTS</button></li>
        <li><button :class="{ active: view == 'contact' }" @click="setView('contact')">KONTAKT</button></li>
        <li><button :class="{ active: view == 'library' }" @click="setView('library')">BUCHHANDLUNG</button></li>
        <li><button :class="{ active: view == 'stationery' }" @click="setView('stationery')">SCHREIBWAREN</button></li>
      </ul>
    </div>
    
    <img :class="{ active: view == 'intro' }" @click="setView('intro')" src="@/assets/beurer-logo.png" class="logo"> 

</div>

  <div class="main">

    <!-- <img href="book.jpg"/> -->

    <TheIntro v-if="view === 'intro'" @navigate="setView" />
    <TheStationery v-if="view === 'stationery'" />
    <TheLibrary v-if="view === 'library'" />
    <TheEvents v-if="view === 'events'" />
    <TheContact v-if="view === 'contact'" />
    <TheImpressum v-if="view ==='impressum'"/>


    
    <footer class="footer-distributed">
    <div class="footer-left">
        <div>
          <font-awesome-icon :class="{ active: view == 'contact' }" @click="view = 'contact'" class="icons-left" icon="fa-solid fa-location-dot" />
          <p><span>Kirchstraße 25</span> Besigheim</p>
        </div>
        <div>
          <a href="tel:(07143)34438">
          <font-awesome-icon class="icons-left" icon="fa-solid fa-phone" />
          </a>
          <p>07143/34438</p>
        </div>
        <div>
          <font-awesome-icon :class="{ active: view == 'contact' }" @click="view = 'contact'" class="icons-left" icon="fa-solid fa-envelope" />
          <p class="email"><a>p.rieger@beurer-besigheim.de</a></p>
        </div>
    </div>
    <div class="footer-center">
      <div>
        <p class="öffnungszeiten">Öffnungszeiten</p>
      </div>
      <div>
        <!-- <p>Montag - Freitag</p>
        <p>7:00-12:30 und 14:30-18:00</p> -->
        <p>Mittwoch</p>
        <p>7:00-12:30</p>

        <p>Mo, Di, Do, Fr</p>
        <p>7:00-12:30 und 14:30-18:00</p>
  
      </div>
      <div>
        <p>Samstag</p>
        <p>7:30-13:00</p>
      </div>
    </div>
    <div class="footer-right">
      <a href="https://www.facebook.com/BuchhandlungBeurer" target="_blank">
      <font-awesome-icon class="icons-right" icon="fa-brands fa-facebook-f" />
      </a>
      <a href="https://www.mein-besigheim.de" target="_blank">
      <font-awesome-icon class="icons-right" icon="fa-solid fa-heart" />
      </a>
      <div>
        <p :class="{ active: view == 'impressum' }" @click="view = 'impressum'" class="Impressum">Impressum</p>
      </div>
    </div>
  </footer>
  <p class="footer-company-name">Copyright © 2024 WebDev Besigheim</p>
  </div>

</div>


</template>


<style scoped>

.menu{
  font-family: 'Akshar', sans-serif;
  color: black;
  text-decoration: none;

}
.menu button {
  color: black;
  text-decoration: none;
}

.container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 65px calc(100vh - 65px);
    grid-template-areas: 
        "header"
        "main";
    gap: 0;
    width: 100%;
}
.logo{
  padding-top: 10px;
  padding-left: 10px;
  height: 60px;
  width: 105px;
  margin-right: auto;
}
.menu {
  display: flex;
  /* margin: 0 auto; */
  width: 100%;
  justify-content: center;
  /* position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
}
.header {
  grid-area: header;
  display: flex;
  /* background-color: rgb(152, 207, 255); */
  border-bottom: solid 1px #333;
  flex-direction: row-reverse;
  justify-content: space-between;
}


.header ul {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  height: 100%;
  /* justify-content: center; */

}

.header li {
  width: 100%;
  height: 100%;
  /* padding-right: 10px; */
  padding: 0 10px;
}

.header li button {
  display: block;
  background-color: white;
  padding: 0 16px;
  border: none;
  height: 100%;
  font-size: 20px;
  font-family: 'Akshar', sans-serif;
  text-decoration:none;
}

.header li button:hover:not(.active) {
  color: rgb(255, 213, 0);
  transition: .2s ease;
}

.header .active {
  color: rgb(255, 213, 0);
}

.main { 
  grid-area: main;
  overflow: auto;
}

.facebook-icon{
    color: rgb(12, 32, 72);
    margin-top: 23px;
    margin-right: 20px;
    font-size: 20px;
}

.facebook-icon:hover{
    color: rgb(255, 213, 0);
    transition: .2s ease;

}

.button-outline{
  height: 100%;
  width: 140px;
  border: 1px solid black;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: solid;
}

.button{
  text-decoration: none;
  display: flex-end;
}
.button a{
  text-decoration: none;
}

.shop-link{
  top: 19px;
  left: 0%;
  font-size: 20px;
  color: black;
  text-decoration: none;
}
.shop-link:hover{
    color: white;
    text-decoration: none;
}
.button-outline p:hover{
    color: white;
    text-decoration: none;

}
.button-outline:hover{
    color: white;
    text-decoration: none;

}

.overlay {
  font-size: 20px;  
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(12, 32, 72);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .15s ease;
  z-index: 1;
}
.overlay p{
    top: 19px;
}
.button-outline:hover .overlay {
  height: 100%;
}

.square {
  display: none;
  width: 65px;
  background-color: rgb(255, 213, 0);
  position: sticky;
  transition: 0.5s ease;
}

.square:hover {
  background-color: rgb(255, 196, 0);
}

.hamburger-line1 {
  height: 2px;
  width: 20px;
  background-color: black;
  margin-left: 23px;
  margin-top: 25px;
}

.hamburger-line2 {
  height: 2px;
  width: 20px;
  background-color: black;
  margin-left: 23px;
  margin-top: 5px;
}

.hamburger-line3 {
  height: 2px;
  width: 20px;
  background-color: black;
  margin-left: 23px;
  margin-top: 5px;
}

@supports (-webkit-touch-callout: none) {
  @media all and (max-width: 800px){
    .title {
      font-size: 60px;
      margin-top: 15vh;
    }
  }
  @media all and (max-width: 500px){
    .title {
      font-size: 60px;
      margin-top: 25vh;
    }
  }
}

.footer {
  grid-area: footer;
  position: fixed;
  bottom: 0;
}

.footer-distributed{
  background-color: rgb(12, 32, 72);
  box-sizing: border-box;
  width: 100%;
  height: auto;
  text-align: left;
  font: bold 16px sans-serif;
  padding: 50px 50px 60px 50px;
  margin-top: 5px;
}
.footer-distributed .footer-left, .footer-distributed .footer-center, .footer-distributed .footer-right{
  display: inline-block;
  vertical-align: top;
}
.footer-distributed .footer-left{
  width: 35%;
}
.footer-distributed .footer-center{
  width: 30%;
  text-align: center;
}
.footer-distributed .footer-right{
  width: 30%;
  text-align: center;
}
.icons-left{
  background-color: rgb(12, 32, 72);
  color: rgb(255, 255, 255);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
  padding: 7px;
}

.footer-distributed .footer-left p{
  display: inline-block;
  color:#ffff;
  vertical-align: middle;
  padding: 6px;
}
.footer-distributed .footer-center p{
  padding: 4px;
}
.footer-distributed .footer-left p span{
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}
.footer-company-name{
  margin-top: -3vh;
  color: #8f9296;
  font-size: 14px;
  font-weight: normal;
  width: 100%;
  text-align: center;
  position: absolute;
  /* padding-top: 30px; */
  /* margin-bottom: 5px; */
}

@media (max-width: 700px) and (orientation: landscape){
  .footer-company-name{
    margin-top: -6vh;
  }
}

@media only screen and (max-width: 500px){
  .footer-company-name{
    margin-top: -4vh;
  }
}

p{
  color: white;
}
.Impressum:hover, .icons-right:hover, .icons-left:hover {
  color: rgb(255, 213, 0);
}
.fa-heart:hover{
  color: red;
}
.icons-right{
  display: inline-block;
  cursor: pointer;
  background-color: rgb(12, 32, 72);
  border-radius: 2px;
  font-size: 23px;
  color:#ffff;
  text-align: center;
  line-height: 35px;
  padding: 6px;
}
.öffnungszeiten{
  margin-bottom: 15px;
  font-weight: bold;
}

.email {
  font-size: 15px;
}
@media all and (max-width: 914px){
  .email {
    font-size: 10px;
}
}

@media all and (max-width: 450px){
  .footer-distributed .footer-left{
    width: 100%;
    margin-left: 0%;
}
}

@media all and (max-width: 700px){
  .header-img {
    object-position: bottom left 10px;
}

.scroll-up{
    width: 100%;
    height: 60px;
    background-color: rgb(12, 32, 72);
    margin-top: 10px;
}

.arrow{
    margin-top: 8px;
    font-size: 50px;
    color: white;
}
.overlay2 {
  font-size: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(255, 213, 0);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .15s ease;
  z-index: 1;
}
.scroll-up:hover .overlay2{
  height: 100%;
}
.footer {
  grid-area: footer;
  position: fixed;
  bottom: 0;
}

.footer-distributed{
  background-color: rgb(12, 32, 72);
  box-sizing: border-box;
  width: 100%;
  height: auto;
  text-align: left;
  font: bold 16px sans-serif;
  padding: 60px 50px 60px 50px;
  /* margin-top: 40px; */
}
.footer-distributed .footer-left, .footer-distributed .footer-center, .footer-distributed .footer-right{
  display: inline-block;
  vertical-align: top;
}
.footer-distributed .footer-left{
  width: 35%;
  margin-left: -10%;
}
.footer-distributed .footer-center{
  width: 30%;
  text-align: center;
}
.footer-distributed .footer-right{
  width: 30%;
  text-align: center;
}
.icons-left{
  background-color: rgb(12, 32, 72);
  color: rgb(255, 255, 255);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
  padding: 7px;
}

.footer-distributed .footer-left p{
  display: inline-block;
  color:#ffff;
  vertical-align: middle;
  padding: 6px;
}
.footer-distributed .footer-center p{
  padding: 4px;
}
.footer-distributed .footer-left p span{
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

p{
  color: white;
}
.Impressum:hover, .icons-right:hover, .icons-left:hover {
  color: rgb(255, 213, 0);
  cursor: pointer;
}
.fa-heart:hover{
  color: red;
}
.icons-right{
  display: inline-block;
  cursor: pointer;
  background-color: rgb(12, 32, 72);
  border-radius: 2px;
  font-size: 23px;
  color:#ffff;
  text-align: center;
  line-height: 35px;
  padding: 6px;
}
.öffnungszeiten{
  margin-bottom: 15px;
  font-weight: bold;
}
@media all and (max-width: 960px){
  .footer-distributed .footer-center, .footer-distributed .footer-right{
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
  .icons-left{
    margin-left: 0;
  }
  .footer-distributed{
    width: 100%;
    height: 625px;
  }
  .footer-distributed .footer-left{
    width: 100%;
    padding-left: 20%;
    margin-bottom: 30px;
  }

  .Öffnungszeiten{
    margin-top: 20px;
  }
}
}

@media screen and (max-width: 1100px) {

  .open-menu {
    opacity: 1;
    height: 150px;
  }
  .closed-menu {
    opacity: 0;
    height: 0;
    padding: 0;
  }
  .menu {
    display: flex;
    flex-direction: column;
    z-index: 100;
    position: absolute;     /* absolute to push div below header */
    width: 100%;
    margin-top: 65px;
    background-color: rgb(12, 32, 72);
  }
  .header ul {
    flex-direction: column;
    width: 100%;
    background-color: rgb(12, 32, 72);
    padding-top: 50px;
  }
  .header li{
    background-color: rgb(12, 32, 72);
    width: 100%;
  }
  .header li button {
    display: block;
    background-color: rgb(12, 32, 72);
    color: white;
    /* padding-left: 40%; */
    padding-bottom: 30px;
    width: 100%;
    text-align: center;
  }
  .square {
    display: block;
    /* padding: 0 10px 10px 0; */
  }
  .symbols {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .shop-link{
  font-size: 18px;
  padding-top: 1px;

}
}

@media screen and (max-width: 600px) {
.shop-link{
  font-size: 18px;
}
}
@media screen and (max-width: 550px) {
.shop-link{
  font-size: 17px;
  padding-top: 3px;
}
.footer-distributed .footer-left{
    width: 100%;
    padding-left: 0%;
}
}
@media screen and (max-width: 410px) {
  .footer-distributed .footer-left{
    margin-left: 0vh;
  }
}

@media screen and (max-width: 400px) {
  .shop-link{
    font-size: 15px;
    padding-top: 5px;
  }
}

@media all and (max-width: 700px){
  .footer-distributed .footer-center, .footer-distributed .footer-right{
    display: block;
    width: 100%;
    /* margin-bottom: 40px; */
    text-align: center;
  }
  .icons-left{
    margin-left: 0;
  }
  /* .footer-distributed{
    width: 100%;
    height: 580px;
  } */
  .footer-distributed .footer-left{
    width: 100%;
    margin-left: 5%;
  }

  .Öffnungszeiten{
    margin-top: 20px;
  }
}



</style>
