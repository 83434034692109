<script setup>
import { onMounted } from 'vue';

onMounted(() => {
    document.querySelector('.header-img').scrollIntoView()
    document.querySelector('.logo').scrollIntoView()
})
</script>

<template>

    <img src="@/assets/buchhandlung-header.jpg" alt="" class="header-img">
    <p class="section-title">BUCHHANDLUNG</p>
    <div class="line"></div>
    
    <div class="text-box">
        <p class="text">
            Im 1. Stock befindet sich die Buchhandlung mit einem liebevoll ausgewählten Sortiment und Geschenkartikel „Dies & Das - für jeden was“. 
            Hier werden Sie in gemütlicher Atmosphäre persönlich und individuell beraten. 
            Sollten Sie in unserem Sortiment nicht fündig werden, ist es uns möglich (fast) jedes Buch über Nacht zu besorgen.
            Durch persönliche Geschenketipps oder die passende Lektüre aus dem Fundus neuerBücher und Bestseller möchten wir unsere Kunden zufriedenstellen.
            Selbstverständlich packen wir Ihre neu erworbenen Schätze anschließend gerne liebevoll ein.
        </p>
    </div>
    <a class="header-box" href="https://www.buchkatalog-reloaded.de/webapp/wcs/stores/servlet/SearchDisplay?storeId=170208&catalogId=10002&langId=-3&pageSize=10&beginIndex=0&sType=SimpleSearch&resultCatEntryType=2&showResultsPage=true&pageView=&pageType=PK&searchTerm=ebooks&searchBtn=&mediaTypes=All%20Media" target="_blank">
        <p class="section-title2">E-BOOKS</p>
    </a>
    <div class="text-box2">
        <p class="text">
            Sie schätzen die Vorteile eines E-Book Readers? Ein Anruf genügt
            und sofort senden wir Ihnen Ihren Buchwunsch auf elektronischem 
            Wege zu.
        </p>
    </div>

    <img src="@/assets/buchhandlung-collage3.jpg" alt="" class="collage">

</template>

<style scoped>

.header-img {
    /* width: 213.6vh;
    height: 40vh;;
    object-fit: cover; */
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */
    width: 100%;
    /* margin-top: -0.55px; */
    /* z-index: -100; */
}

.buttons{
    display: flex;
    margin-top: -6px;
    background-color: rgb(238, 238, 238);
    width: 100%;
    height: 60px;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
}

.line2{
    width: 2px;
    height: 60px;
    background-color: black;
    margin-left: 50%;
    margin-top: 10px;
    margin-bottom: 80px;
}
.section-title{
    padding-top: 50px;
    font-size: 50px;
    color: black;
}

.section-title2 {
    padding-top: 50px;
    font-size: 40px;
    color: black;
    text-decoration: none;
}

.line{
    width: 80%;
    height: 2px;
    background-color: black;
    margin-left: 10%;
    margin-top: 50px;
}

.text-box{
    width: 60%;
    margin-left: 20%;
    height: 50px;
    margin-top: 90px;
    margin-bottom: 330px;
}

@media all and (max-width: 900px){
    .text-box {
        width: 80%;
        margin-left: 10%;    
    }
    .text-box2 {
        width: 80%;
        margin-left: 10%;    
    }
    .collage {
    max-width: 80%;
    height: auto;
    width: auto\9; /* ie8 */
    width: 80%;
    margin-bottom: 80px;
}
}

.text-box2{
    width: 60%;
    margin-left: 20%;
    height: 50px;
    margin-top: 90px;
    margin-bottom: 30px;
}
@media all and (max-width: 900px){
    .text-box2 {
        width: 80%;
        margin-left: 10%;    
    }
}

.header-box :hover{
    color: rgb(255, 213, 0);
}

.header-box{
text-decoration: none;
}

.text{
    font-size: 30px;
    text-align: justify;
}

.collage{
    margin-top: 80px;
    max-width: 60%;
    height: auto;
    width: auto\9; /* ie8 */
    width: 60%;
    margin-bottom: 80px;
}
@media all and (max-width: 900px){
    .collage{
    margin-top: 80px;
    max-width: 80%;
    height: auto;
    width: auto\9; /* ie8 */
    width: 80%;
    margin-bottom: 80px;
}
}

@media all and (max-width: 1500px){
    .text{
        font-size: 27px;
    }
}
@media all and (max-width: 1300px){
    .text{
        font-size: 25px;
    }
}
@media all and (max-width: 1000px){
    .text{
        font-size: 23px;
        margin-top: 60px;
    }
}
@media all and (max-width: 800px){
    .text{
        font-size: 20px;
        margin-top: 60px;

    }
    .section-title{
    font-size: 45px;
    }
}
@media all and (max-width: 700px){
    .text{
        font-size: 19px;
        margin-top: 60px;

    }
    .section-title{
    font-size: 40px;
    }
}
@supports (-webkit-touch-callout: none) {
    @media all and (max-width: 700px){
    .text-box{
    width: 60%;
    margin-left: 20%;
    height: 50px;
    margin-top: 90px;
    margin-bottom: 300px;
    }
    .collage{
    margin-top: 80px;
    max-width: 60%;
    height: auto;
    width: auto\9; /* ie8 */
    width: 60%;
    margin-bottom: 80px;
    }
    }
}
@media all and (max-width: 600px){
    .text{
        font-size: 18px;
        margin-top: 70px;
        margin-bottom: 20px;

    }
    .section-title{
    font-size: 40px;
    }
}
@media all and (max-width: 500px){
    .text{
        font-size: 17px;
        margin-top: 80px;
        margin-bottom: 20px;

    }
    .section-title{
    font-size: 35px;
    }
    .books{
    font-size: 15px;
    margin-top: 18px;
    }
    .e-books{
    font-size: 15px;
    margin-top: 18px;
    }
}
@supports (-webkit-touch-callout: none) {
    @media all and (max-width: 500px){
    .text-box{
    width: 80%;
    margin-left: 10%;
    height: 50px;
    margin-top: 90px;
    margin-bottom: 300px;
    }
    .text{
    font-size: 30px;
    text-align: justify;
    }
    .collage{
    margin-top: 30px;
    max-width: 80%;
    height: auto;
    width: auto\9; /* ie8 */
    width: 80%;
    margin-bottom: 80px;
    }
    .text-box2{
    width: 80%;
    margin-left: 10%;
    height: 50px;
    margin-top: 90px;
    margin-bottom: 30px;
    }
    }
}


@media all and (max-width: 460px){
    .text{
        font-size: 16px;
    }
    .section-title{
    font-size: 30px;
    }
}
@media all and (max-width: 400px){
    .text{
        font-size: 15px;


    }
    .section-title{
    font-size: 30px;
    }

}
@media all and (max-width: 352px){
    .text{
        font-size: 14px;
    }
    .section-title{
    font-size: 30px;
    }

}

</style>