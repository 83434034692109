<script setup>
import { onMounted, reactive } from 'vue';
import axios from 'axios';
const querystring = require('querystring');

// let sent = false;
const contactForm = reactive({
    name: '',
    email: '',
    subject: '',
    message: ''
})

onMounted(() => {
    document.querySelector('.header-img').scrollIntoView()
    document.querySelector('.logo').scrollIntoView()
})

// Source (see 3rd solution): https://bitcoden.com/answers/how-to-send-an-email-with-vuejs
function sendForm(e) {
    e.preventDefault();
    console.log(querystring.stringify(contactForm));
    axios.post('mail.php', querystring.stringify(contactForm)).then(() => {
        // sent = true;
    }).catch(err => console.log(err));
}
</script>


<template>
        <img src="@/assets/contact-header.jpg" alt="" class="header-img">

    <div class="main">
        <p class="section-title">KONTAKT</p>
        <div class="line"></div>
        <div class="text-box">
            <p class="header">KONTAKTIEREN SIE UNS</p>
            <p class="text">Falls sie uns etwas mitteilen wollen oder Fragen haben, können sie unser Kontaktformular ausfüllen und/oder eine E-Mail schreiben.</p>
        </div>


        <section class="contact-us">
        <div class="contact-col">
            <div>
                <!-- <font-awesome-icon icon="fa-solid fa-user-secret" class="fa-solid" /> -->
                <font-awesome-icon icon="fa-solid fa-house" class="fa-solid" />
                <span>
                    <h2 class="bold">Kirchstraße 25</h2>
                    <p>74354 Besigheim</p>
                </span>
            </div>
            <div>
                <a href="tel:(07143)34438">
                    <font-awesome-icon icon="fa-solid fa-phone" class="fa-solid" />
                </a>
                <span>
                    <!-- <a class="bold" href="tel:123-456-7890">07143/2081560</a> -->
                    <h2 class="bold">Schreibwaren: (07143)34438</h2>
                    <h2 class="bold">Buchhandlung: 32699</h2>
                    <p>Während den Öffnungszeiten</p>
                </span>
                
            </div>
            <div>
                <a href="mailto:p.rieger@beurer-besigheim.de" > 
                    <font-awesome-icon icon="fa-solid fa-envelope" class="fa-solid" />
                </a>
                <span>
                    <!-- <a class="bold" href="mailto:kontakt@sportstaettenrestaurant-besigheim.de"> kontakt@sr-besigheim.de </a> -->
                    <h2 class="bold" mailto>p.rieger@beurer-besigheim.de</h2>
                    <p>Bei fragen gerne melden</p>
                </span>
            </div>
        </div>
        <div class="contact-col">
            <form v-on:submit="sendForm">
                <input type="text" v-model="contactForm.name" placeholder="Gib deinen Namen ein" required>
                <input type="email" v-model="contactForm.email" placeholder="Gib deine Emailadresse ein" required>
                <input type="text" v-model="contactForm.subject" placeholder="Gib den Betreff ein" required>
                <textarea rows="8" v-model="contactForm.message" placeholder="Nachricht" required></textarea>
                <button type="submit" class="button2">NACHRICHT VERSENDEN</button>
            </form>
        </div>
    </section>


    <div class="map">       
        <p class="map-title">
            <font-awesome-icon icon="fa-solid fa-map-location-dot" class="map-icon" /> 
            ANFAHRT
        </p>
        <p class="directions">
            Das Geschäft befindet sich im Kern der Altstadt 
            Besigheims, also von jeder Richtung erreichbar zu Fuß. 
            Parkmöglichkeiten auf dem Marktplatz oder im Parkhaus nebenan. 
            Von dort sind es bloß drei schnelle Minuten zu Fuß.
        </p>
        <div class="location">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2617.5564971051112!2d9.13940965152825!3d48.99999687919998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4799d4adaf7aacdf%3A0x53e7fa68807f00d7!2sBookstore%20Beurer%20Besigheim%20stationery!5e0!3m2!1sen!2sde!4v1664728503485!5m2!1sen!2sde" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>


    </div>


</template>


<style scoped>

hr{
margin-top: 65px;
border-top: 1px solid gray;
margin-bottom: -1px;
}

.header-img{
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */
    width: 100%;
    /* margin-top: -0.55px; */
    /* z-index: -100; */
}


.section-title{
    padding-top: 50px;
    font-size: 50px;
    color: black;
}
.line{
    width: 80%;
    height: 2px;
    background-color: black;
    margin-left: 10%;
    margin-top: 50px;
}
.text-box{
    width: 60%;
    margin-left: 20%;
    height: 50px;
    margin-top: 90px;
    margin-bottom: 200px;
}
.header{
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
}
.text{
    font-size: 25px;
    text-align: center;
}

.ku{
    font-size: 25px;
    text-align: center;
    margin-top: 100px;
}
.help{
    font-size: 20px;
    text-align: center;
    margin-top: 30px;
    width: 55%;
    margin-left: 23.5%;
}

.contact-us{
    display: flex;
    width: 80%;
    margin: auto;
    margin-top: 100px;
    text-align: left;
}

.contact-col{
    margin-bottom: 30px;
    width: 50%;
    padding-right: 20px;
    
}
.contact-col div{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    /* margin-left: 25%; */

}
.contact-col div .fa-solid {
    font-size: 28px;
    color: rgb(255, 208, 0);
    margin: 10px;
    margin-right: 30px;
    transition: ease 0.5s;
}

.contact-col div .fa-solid:hover {
    color: rgb(12, 32, 72);;
}

.contact-col div p{
    padding: 0;
}
.contact-col div h2{
    font-size: 20px;
    margin-bottom: 5px;
    color: #555;
    font-weight: 400;
}
.contact-col input, .contact-col textarea{
    width:100%;
    padding: 15px;
    margin-bottom: 17px;
    outline:none;
    border: 1px solid #ccc;
    box-sizing: border-box;
}
.hr3{
    height: 2px;
    background: #00aaff;
    margin-top: -80px;
    width: 20%;
    margin-bottom: 10%;
    /* position: absolute; */
}
.hr4{
    height: 2px;
    background: #00aaff;
    margin-top: -53px;
    width: 20%;
    /* margin-left: 80%; */
    margin-bottom: 10%;

}
.button2{
    margin-top: 0px;
    display: inline-block;
    color:#000000;
    text-decoration: none;
    border: 2px solid rgb(234, 195, 0);;
    padding: 12px 30px;
    font-size: 15px;
    background: transparent;
    cursor: pointer;
    margin-bottom: 10%;
}
.button2:hover{
    border: 2px solid #ffdd0080;
    background: rgb(255, 213, 0);;
    transition: 1s;
}
.k{
    color: #000000;
}
.inf{
    color: #000000;
    position: relative;
}







.location{
    display: flex;
}

.map-icon{
    font-size: 38px;
    color: rgb(255, 208, 0);
    padding-right: 20px;
}

.map{
    margin-top: 50px;
    width: 100%;
    height: 900px;
    background-color: rgb(12, 32, 72);
    
}
.location{
    width: 80%;
    margin: auto;
    padding: 80px 0;
}
.location iframe{
    width: 100%;
    height: 400px;
}
/* .contact-col a{
    font-size: 20px;
    color: #000000;
} */
.map-title{
    font-size: 45px;
    color: white;
    width: 80%;
    margin: 0%;
    text-align: left;
    margin-left: 10%;
    padding-top: 100px;
}
.directions{
    margin-top: 30px;
    margin-bottom: -20px;
    font-size: 22px;
    color: white;
    width: 80%;
    margin-left: 10%;
    text-align: justify;
}



@media all and (max-width: 1500px){
    .text{
        font-size: 27px;
        margin-top: 10px;

    }
}
@media all and (max-width: 1300px){
    .text{
        font-size: 25px;
        margin-top: 10px;

    }
}
@media all and (max-width: 1000px){
    .text{
        font-size: 23px;
    }
}
@media all and (max-width: 800px){
    .text{
        font-size: 20px;
        margin-top: 10px;
    }
    .section-title{
    font-size: 45px;
    }
    iframe{
    height: 400px;
}
}


@media all and (max-width: 700px){
    .text{
        font-size: 19px;
        margin-top: 10px;
    }
    .section-title{
    font-size: 40px;
    }

.map-icon{
    font-size: 35px;
}

iframe{
    height: 400px;
}
.location{
    width: 80%;
    margin: auto;
    padding: 80px 0;
}
.map-title{
    font-size: 40px;
    text-align: left;
}
.directions{
    font-size: 20px;
}
}


@media all and (max-width: 600px){
    .text{
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 10px;


    }
    .section-title{
    font-size: 40px;
    }
    .text-box{
    margin-bottom: 250px;
}
}




@media all and (max-width: 550px){
    .text{
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 10px;
    }
    .section-title{
    font-size: 37px;
    }
    .contact-us{
    display: block;
    width: 80%;
    margin: auto;
    margin-top: 100px;
    text-align: left;
}

.contact-col{
    margin-bottom: 40px;
    width: 100%;
    padding-right: 0px;
    align-items: center;
    
}
.contact-col div{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    /* margin-left: 25%; */

}
.contact-col div .fa-solid {
    font-size: 28px;
    color: rgb(255, 208, 0);
    margin: 10px;
    margin-right: 30px;
}

.contact-col div p{
    padding: 0;
}
.contact-col div h2{
    font-size: 20px;
    margin-bottom: 5px;
    color: #555;
    font-weight: 400;
}
.contact-col input, .contact-col textarea{
    width:100%;
    padding: 15px;
    margin-bottom: 17px;
    outline:none;
    border: 1px solid #ccc;
    box-sizing: border-box;
}
.hr3{
    height: 2px;
    background: #00aaff;
    margin-top: -80px;
    width: 20%;
    margin-bottom: 10%;
    /* position: absolute; */
}
.hr4{
    height: 2px;
    background: #00aaff;
    margin-top: -53px;
    width: 20%;
    /* margin-left: 80%; */
    margin-bottom: 10%;

}
.button2{
    margin-top: 0px;
    display: inline-block;
    color:#000000;
    text-decoration: none;
    border: 2px solid rgb(234, 195, 0);;
    padding: 12px 30px;
    font-size: 15px;
    background: transparent;
    cursor: pointer;
    margin-bottom: 10%;
}
.button2:hover{
    border: 2px solid #ffdd0080;
    background: rgb(255, 213, 0);;
    transition: 1s;
}
.k{
    color: #000000;
}
.inf{
    color: #000000;
    position: relative;
}

.map-icon{
    font-size: 30px;
}

iframe{
    height: 380px;
}
.map-title{
    font-size: 35px;
}
.directions{
    font-size: 17px;
}
}





@media all and (max-width: 500px){
    .text{
        font-size: 17px;
        margin-top: 10px;
        margin-bottom: 20px;

    }
    .section-title{
    font-size: 35px;
    }
    .header{
        font-size: 25px;
    }

}
@media all and (max-width: 460px){
    .text{
        font-size: 16px;
    }
    .section-title{
    font-size: 30px;
    }
    .header{
        font-size: 20px;
        margin-top: -25px;
        font-weight: 400;
        margin-bottom: 20px;
    }

}
@media all and (max-width: 400px){
    .text{
        font-size: 15px;


    }
    .section-title{
    font-size: 30px;
    }
    .location iframe{
    width: 100%;
    height: 300px;
}

}
@media all and (max-width: 352px){
    .text{
        font-size: 14px;
    }
    .section-title{
    font-size: 30px;
    }

}


</style>
