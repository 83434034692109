<script setup>
import { onMounted } from 'vue';

onMounted(() => {
    document.querySelector('.header-img').scrollIntoView()
    document.querySelector('.logo').scrollIntoView()
})
</script>

<template>

        <img src="@/assets/schreibwahren.jpg" alt="" class="header-img">
        <p class="section-title">SCHREIBWAREN</p>
        <div class="line"></div>
        <div class="text-box">
            <p class="text">
                Beurer ist seit Jahrzehnten ein Begriff in Besigheim! 
                Bekannt sind wir als das führende Fachgeschäft für Papier- und Schreibwaren. 
                Wir statten Ihr Kind mit allen wichtigen Schulmaterialen aus.
                Vom Schulbedarf bis zum Bastelpapier, vom exklusiven Schreibgerät bis zum Schulfüller:<br> Bei uns finden Sie alles, was Sie zum Schreiben, Basteln, Ordnen oder Archivieren benötigen.
                Bekannt sind wir ebenso für eine große Auswahl an Servietten, Glückwunschkarten und Geschenkpapieren zu jedem Anlass.
                Gewünschte und nicht vorrätige Artikel bestellen wir innerhalb kurzer Zeit für Sie!
                Unsere Kunden schätzen uns für das breite Produkt- und Markensortiment, die kompetente Beratung und unseren freundlichen Service. 
            </p>
        </div>
        <img src="@/assets/schreibwaren-collage4.jpg" alt="" class="collage">

</template>

<style scoped>

hr {
    margin-top: 65px;
    border-top: 1px solid gray;
    margin-bottom: -1px;
}

header {
    display: flex;
}

.header-img {
    /* width: 213.6vh;
    height: 40vh;;
    object-fit: cover; */
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */
    width: 100%;
    /* margin-top: -0.55px; */
    /* z-index: -100; */
}

.section-title {
    padding-top: 50px;
    font-size: 50px;
    color: black;
}

.line {
    width: 80%;
    height: 2px;
    background-color: black;
    margin-left: 10%;
    margin-top: 50px;
}

.text-box {
    width: 60%;
    margin-left: 20%;
    height: 50px;
    margin-top: 90px;
    margin-bottom: 330px;
}
@media all and (max-width: 900px){
    .text-box {
        width: 80%;
        margin-left: 10%; 
        margin-bottom: 250px;
    }
}

.text {
    font-size: 30px;
    text-align: justify;
}

.collage {
    margin-top: 180px;
    max-width: 60%;
    height: auto;
    width: auto\9; /* ie8 */
    width: 60%;
    margin-bottom: 80px;
}
@media all and (max-width: 900px){
    .collage {
    max-width: 80%;
    height: auto;
    width: auto\9; /* ie8 */
    width: 80%;
    margin-bottom: 80px;
}
}

@media all and (max-width: 1500px){
    .text {
        font-size: 27px;
    }
}

@media all and (max-width: 1300px){
    .text {
        font-size: 25px;
    }
}

@media all and (max-width: 1000px){
    .text {
        font-size: 23px;
        margin-top: 60px;
    }
}

@media all and (max-width: 900px){
    .collage {
    margin-top: 200px;
    }
}

@media all and (max-width: 800px){
    .text {
        font-size: 20px;
        margin-top: 60px;
    }
    .section-title{
        font-size: 45px;
    }
}

@media all and (max-width: 700px){
    .text {
        font-size: 19px;
        margin-top: 60px;
    }
    .section-title {
        font-size: 40px;
    }
}

@supports (-webkit-touch-callout: none) {
    @media all and (max-width: 700px){
    .text-box {
        width: 60%;
        margin-left: 20%;
        height: 50px;
        margin-top: 90px;
        margin-bottom: 330px;
    }

    .collage {
        margin-top: 40vh;
        max-width: 60%;
        height: auto;
        width: auto\9; /* ie8 */
        width: 60%;
        margin-bottom: 80px;
    }
    }
}

@media all and (max-width: 600px){
    .text {
        font-size: 18px;
        margin-top: 70px;
        margin-bottom: 20px;

    }
    .section-title {
    font-size: 40px;
    }
}
@media all and (max-width: 500px){
    .text {
        font-size: 17px;
        margin-top: 80px;
        margin-bottom: 20px;

    }
    .section-title {
    font-size: 35px;
    }

}
@supports (-webkit-touch-callout: none) {
    @media all and (max-width: 500px){
    .text {
        font-size: 17px;
        margin-top: 80px;
        margin-bottom: 20px;

    }

    .section-title {
        font-size: 35px;
    }

    .collage {
        margin-top: 200px;
    }

    .text-box {
        width: 80%;
        margin-left: 10%;
    }

    .collage {
        margin-top: 50px;
        max-width: 80%;
        height: auto;
        width: auto\9; /* ie8 */
        width: 80%;
        margin-bottom: 80px;
    }
    }
}
@media all and (max-width: 460px){
    .text {
        font-size: 16px;
    }

    .section-title {
        font-size: 30px;
    }
}
@media all and (max-width: 400px){
    .text {
        font-size: 15px;
    }

    .section-title {
        font-size: 30px;
    }

}
@media all and (max-width: 352px){
    .text {
        font-size: 14px;
    }

    .section-title {
        font-size: 30px;
    }
}

</style>